// extracted by mini-css-extract-plugin
export var aboutSection = "var-story-module--aboutSection--ff65f";
export var container = "var-story-module--container--32409";
export var founderColumn = "var-story-module--founderColumn--e6561";
export var founderColumns = "var-story-module--founderColumns--f440c";
export var founderImage = "var-story-module--founderImage--d5286";
export var founderName = "var-story-module--founderName--e381a";
export var foundersSection = "var-story-module--foundersSection--07b35";
export var heroSection = "var-story-module--heroSection--ab6dd";
export var heroSubtext = "var-story-module--heroSubtext--2b84c";
export var newsGrid = "var-story-module--newsGrid--db681";
export var partnerLogo = "var-story-module--partnerLogo--fc088";
export var partnerLogos = "var-story-module--partnerLogos--9922f";
export var uspCard = "var-story-module--uspCard--f23ec";
export var uspsGrid = "var-story-module--uspsGrid--c26d6";
export var uspsSection = "var-story-module--uspsSection--99fda";