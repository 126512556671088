import React from 'react';
import Layout from '@components/Layout/Layout';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import Container from '@src/components/Container';
import * as styles from './var-story.module.scss';
import Card from '@src/components/Card';
import Grid from '@src/components/Grid';
import Stripe from '@src/images/partners/stripe.svg';
import Tink from '@src/images/partners/tink_visa_black.svg';
import Google from '@src/images/partners/google.svg';
import Visa from '@src/images/partners/visa.svg';
import Enfuce from '@src/images/partners/enfuce.svg';
import Blocket from '@src/images/partners/blocket.svg';
import Timeline from '@src/images/rocker-timeline-2023.png';
import RockerUsers from '@src/images/rocker-users.png';
import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import { AppDownloadUrl } from '@src/pageConstants';

function AboutUs({ data }) {
  const {
    contentfulPage: { headLine },
  } = data;

  const founders = [
    {
      name: 'Jonas Hultin',
      image:
        'https://storage.googleapis.com/rocker-marketing-assets/jonas_hultin_v4.jpg',
      title: 'Founder and CEO',
      linkedIn: 'https://www.linkedin.com/in/jonas-hultin-6905818/',
    },
    {
      name: 'Dennis Ahlsén',
      image:
        'https://storage.googleapis.com/rocker-marketing-assets/dennis_ahlsen.jpeg',
      title: 'Founder and Chairman',
      linkedIn: 'https://www.linkedin.com/in/dennis-ahls%C3%A9n-01b2b859/',
    },
    {
      name: 'Mathias Johansson',
      image:
        'https://storage.googleapis.com/rocker-marketing-assets/mathias_johansson.png',
      title: 'Founder',
      linkedIn: 'https://www.linkedin.com/in/mathiasjohansson/',
    },
  ];

  const partners = [
    { logo: Tink },
    { logo: Google },
    { logo: Visa },
    { logo: Stripe },
    { logo: Enfuce },
    { logo: Blocket },
  ];

  return (
    <Layout>
      <SEO title={headLine} />
      <Container className={styles.container}>
        <div className={styles.heroSection}>
          <div>Om oss</div>
          <h1>Vi är Rocker</h1>
          <div className={styles.heroSubtext}>
            Vi löser problemet med komplexa finansiella processer genom att
            erbjuda en enkel och säker plattform. Det som gör oss unika är vårt
            fokus på användarvänlighet, säkerhet och innovation.
          </div>
          <PrimaryButton href={AppDownloadUrl}>Prova gratis</PrimaryButton>
        </div>
        <div className={styles.uspsSection}>
          <Grid className={styles.uspsGrid}>
            <div className={styles.uspCard}>
              <h3>1M+</h3>
              <p>Användare</p>
            </div>
            <div className={styles.uspCard}>
              <h3>170+</h3>
              <p>Länder Rockerkortet använts i</p>
            </div>
            <div className={styles.uspCard}>
              <h3>+286%</h3>
              <p>Rocker Pay tillväxt per år</p>
            </div>
            <div className={styles.uspCard}>
              <h3>4,7+/5</h3>
              <p>Kundnöjdhet</p>
            </div>
          </Grid>
        </div>
        <div className={styles.aboutSection}>
          <h2>Bakgrund</h2>
          <p>
            Rocker AB föddes ur en gemensam frustration 2017. Grundarna Jonas
            Hultin, Dennis Ahlsén och Mathias Johansson, som gjort sig ett namn
            inom fintech-världen med Lendo, kunde inte låta bli att notera ett
            uppenbart problem - bankindustrin halkade efter i den digitala
            revolutionen.
          </p>
          <p>
            På ett café i Tyresö funderade de över varför banktjänster kändes
            fast i det förflutna när andra sektorer snabbt utvecklades. Varför
            kunde kunder exempelvis inte hantera lån direkt från sina mobiler
            med samma enkelhet som andra tjänster kunde skötas från mobilen?
          </p>
          <p>
            Detta var inte bara en irritation - det var ett betydande problem.
            Äldre system bromsade produktutvecklingen inom flera bankområden och
            gjorde det svårt att skapa användarvänliga appar med fokus på
            användarupplevelsen.
          </p>
          <p>
            Driven av denna vision bestämde de sig för att förändra saker och
            ting. Deras lösning? Rocker AB.
          </p>
          <p>
            På bara 3,5 månader lanserade Rocker sin första produkt: ett lån som
            sträcker sig från 10 000 till 100 000 kr, fullt hanterbart direkt
            från Rocker-appen. Men detta var bara början.
          </p>
          Under åren har Rocker rört sig framåt inom fler områden:
          <p>
            <ul>
              <li>
                En betaltjänst (checkout) för begagnatmarknaden på Sveriges
                största köp och säljsajt Blocket.
              </li>
              <li>
                Kort med kopplingar mot alla mobila plånböcker och utrustat med
                fingeravtrycksläsare som första aktör i Norden.
              </li>
              <li>
                Open banking-verktyg designade för att hjälpa individer att
                optimera sin privatekonomi där privatpersoners ekonomiska hälsa
                står i fokus.
              </li>
            </ul>
          </p>
        </div>
        <div className={styles.foundersSection}>
          <h2>Grundare</h2>
          <div className={styles.founderColumns}>
            {founders.map((founder, i) => (
              <div key={i} className={styles.founderColumn}>
                <img
                  className={styles.founderImage}
                  src={founder.image}
                  alt={founder.name}
                />
                <div className={styles.founderName}>{founder.name}</div>
                <p>
                  {founder.title}
                  <br />
                  <a href={founder.linkedIn}>LinkedIn</a>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.aboutSection}>
          <h2>Partners</h2>
          <div className={styles.partnerLogos}>
            {partners.map((partner, i) => (
              <img
                key={i}
                className={styles.partnerLogo}
                src={partner.logo}
                alt="Partner logo"
              />
            ))}
          </div>
        </div>

        <div className={styles.aboutSection}>
          <h2>Användare</h2>
          <img src={RockerUsers} width="95%"></img>
        </div>

        <div className={styles.aboutSection}>
          <h2>Milstolpar</h2>
          <img src={Timeline} width="95%"></img>
        </div>
      </Container>
    </Layout>
  );
}

export default AboutUs;

export const query = graphql`
  query VarStoryQuery($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/var-story" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 1000)
      }
      pageContent {
        ... on ContentfulTextSection {
          title
          content {
            content
          }
          image {
            title
            gatsbyImageData(width: 688)
          }
        }
      }
    }
  }
`;
